// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-forgotten-password-2-tsx": () => import("./../../../src/pages/forgotten-password-2.tsx" /* webpackChunkName: "component---src-pages-forgotten-password-2-tsx" */),
  "component---src-pages-forgotten-password-email-sent-tsx": () => import("./../../../src/pages/forgotten-password-email-sent.tsx" /* webpackChunkName: "component---src-pages-forgotten-password-email-sent-tsx" */),
  "component---src-pages-forgotten-password-success-tsx": () => import("./../../../src/pages/forgotten-password-success.tsx" /* webpackChunkName: "component---src-pages-forgotten-password-success-tsx" */),
  "component---src-pages-forgotten-password-tsx": () => import("./../../../src/pages/forgotten-password.tsx" /* webpackChunkName: "component---src-pages-forgotten-password-tsx" */),
  "component---src-pages-get-in-touch-tsx": () => import("./../../../src/pages/get-in-touch.tsx" /* webpackChunkName: "component---src-pages-get-in-touch-tsx" */),
  "component---src-pages-home-event-tsx": () => import("./../../../src/pages/home-event.tsx" /* webpackChunkName: "component---src-pages-home-event-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-partnerships-tsx": () => import("./../../../src/pages/partnerships.tsx" /* webpackChunkName: "component---src-pages-partnerships-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-register-success-tsx": () => import("./../../../src/pages/register-success.tsx" /* webpackChunkName: "component---src-pages-register-success-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-search-portfolios-holding-tsx": () => import("./../../../src/pages/search-portfolios-holding.tsx" /* webpackChunkName: "component---src-pages-search-portfolios-holding-tsx" */),
  "component---src-pages-search-portfolios-tsx": () => import("./../../../src/pages/search-portfolios.tsx" /* webpackChunkName: "component---src-pages-search-portfolios-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-signed-out-tsx": () => import("./../../../src/pages/signed-out.tsx" /* webpackChunkName: "component---src-pages-signed-out-tsx" */),
  "component---src-pages-social-wall-tsx": () => import("./../../../src/pages/social-wall.tsx" /* webpackChunkName: "component---src-pages-social-wall-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-universities-tsx": () => import("./../../../src/pages/universities.tsx" /* webpackChunkName: "component---src-pages-universities-tsx" */),
  "component---src-templates-partner-post-tsx": () => import("./../../../src/templates/partner-post.tsx" /* webpackChunkName: "component---src-templates-partner-post-tsx" */),
  "component---src-templates-student-post-tsx": () => import("./../../../src/templates/student-post.tsx" /* webpackChunkName: "component---src-templates-student-post-tsx" */),
  "component---src-templates-university-post-tsx": () => import("./../../../src/templates/university-post.tsx" /* webpackChunkName: "component---src-templates-university-post-tsx" */)
}

