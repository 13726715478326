// custom typefaces
import "@fontsource/barlow-condensed/400.css"
import "@fontsource/barlow-condensed/500.css"
import "@fontsource/barlow-condensed/600.css"

import SwiperCore, { Navigation, Scrollbar, EffectCoverflow, Autoplay } from "swiper"

// Import Swiper styles
import "swiper/swiper.scss"
import "swiper/components/navigation/navigation.scss"
import "swiper/components/pagination/pagination.scss"
import "swiper/components/scrollbar/scrollbar.scss"

// install Swiper components
SwiperCore.use([Navigation, Scrollbar, EffectCoverflow, Autoplay])
